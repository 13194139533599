import { Injectable, inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, catchError, delay, throwError } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/services/toast-notification.service';
import { ToastNotificationType } from 'src/app/shared/enums/toast-notification-type.enum';
import { NotificationType } from 'src/app/shared/enums/notification-type.enum';
import { LocalStorageService } from '../services/local-storage.service';
import { Router } from '@angular/router';
import { MessagingHubService } from 'src/app/features/common/services/messaging-hub.service';
@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private readonly _toastNotificationService = inject(ToastNotificationService);
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _messagingHubService = inject(MessagingHubService);
  private readonly _router = inject(Router);
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this._toastNotificationService.showToast(
            NotificationType.APP_USER_UNAUTHORIZED,
            {
              severity: ToastNotificationType.error,
              life: 5000,
            }
          );
          this.handleUnauthorizedError();
        }
        return throwError(() => error); // Diğer hataları dışa ilet.
      })
    );
  }

  private handleUnauthorizedError(): void {
    setTimeout(() => {
      this._messagingHubService.stopChatConnection();
      this._localStorageService.removeItem('user');
      this._router.navigate(['/auth/login']);
    }, 5000);
  }
}
